import React, { useState } from 'react'

//MUI
import { AppBar, Card, CardContent, Container, Fab, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

//Components
import AddWebinar from './AddWebinar';

//react-beatufil-dnd
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//Webinars prueba
import video_1 from '../../../Assets/Resources/Webinars/Adiposidad y Progresión de Daño Renal.mp4'
import video_2 from '../../../Assets/Resources/Webinars/Aplicabilidad de la Dieta de la Milpa en la Enfermedad Renal.mp4'
import video_3 from '../../../Assets/Resources/Webinars/MANEJO DE HIPERFOSFATEMIA EN LA ENFERMEDAD RENAL.mp4'
const data = [
    {
        id: 1,
        date: 'Septiembre 23, 2024',
        video: video_1
    },
    {
        id: 2,
        date: 'Octubre 22, 2024',
        video: video_2
    },
    {
        id: 3,
        date: 'Noviembre 10, 2024',
        video: video_3
    }
]

const CustomFab = styled(Fab)(({ theme }) => ({
    backgroundColor: '#283A49',
    '&:hover': {
        backgroundColor: '#405668',
    },
}));

const Webinars = () => {

    //Open menu
    const [anchorMenu, setAnchorMenu] = useState(null);
    const open = Boolean(anchorMenu);
    const handleOpenMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorMenu(null);
    };

    //Open dialog
    const [dialog, setDialog] = useState(false)

    const handleOpen = () => {
        setDialog(true)
    }

    const [webinars, setWebinars] = useState(data)

    //drag
    const handleDrag = (result) => {
        if (!result.destination) return;

        const arr = [...webinars];

        //Changing the position of Array element
        let removedItem = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, removedItem);

        //Updating the list
        setWebinars(arr);
    };

    //valores para localstorage (unicamente pruebas)
    const loaded = localStorage.getItem("webinar")
        ? JSON.parse(localStorage.getItem("webinar"))
        : [];

    const [formValues, setFormValues] = useState(loaded);

    return (
        <>
            <Container maxWidth='xl' sx={{ my: 5 }}>
                <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '28px' }} paragraph>Webinars publicados</Typography>
                <DragDropContext onDragEnd={handleDrag}>
                    <Droppable droppableId="webinars">
                        {(provided) => (
                            <Grid container spacing={5} {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    webinars.map((res, index) => (
                                        <Draggable
                                            key={res.id}
                                            draggableId={res.id.toString()}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Card sx={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                                        <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                                                            <Toolbar>
                                                                <Typography variant='body1' sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>Webinar {res.date}</Typography>
                                                                <IconButton onClick={handleOpenMenu}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Toolbar>
                                                            <Menu
                                                                anchorEl={anchorMenu}
                                                                open={open}
                                                                onClose={handleCloseMenu}
                                                            >
                                                                <MenuItem>Editar</MenuItem>
                                                                <MenuItem>Eliminar</MenuItem>
                                                            </Menu>
                                                        </AppBar>
                                                        <CardContent>
                                                            <video src={res.video}
                                                                width="100%" height="auto" controls="controls" controlsList="nodownload"
                                                            />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>

            <CustomFab sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                <AddIcon sx={{ color: '#fff' }} />
            </CustomFab>

            <AddWebinar dialog={dialog} setDialog={setDialog} formValues={formValues} setFormValues={setFormValues} />
        </>
    )
}

export default Webinars