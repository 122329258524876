import React, { useState } from 'react'

import './AdminInformesCientificos.css'

//MUI
import { Box, Container, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';

import img from '../../../Assets/Images/Simbiotico.jpg'
import img_1 from '../../../Assets/Images/Malteada-Nutritiva-vainilla-490g_1.jpg'

import protocol from '../../../Assets/Resources/protocolos/reporte_preliminar_simbiotico_nin.pdf'
import protocol_1 from '../../../Assets/Resources/protocolos/Malteada_Nutritiva_NIN_preliminar_junio-2024.pdf'

//Components
import InformesCientificosModal from './InformesCientificosModal';

//react-beatufil-dnd
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CustomFab = styled(Fab)(({ theme }) => ({
    backgroundColor: '#283A49',
    '&:hover': {
        backgroundColor: '#405668',
    },
}));

const data = [
    {
        id: 1,
        name: 'Informe científico Simbiotico NIN',
        file: protocol,
        date: 'Noviembre 4, 2024',
        image: img
    },
    {
        id: 2,
        name: 'Informe científico Malteada nutritiva NIN',
        file: protocol_1,
        date: 'Noviembre 6, 2024',
        image: img_1
    },
    {
        id: 3,
        name: 'Informe científico Malteada nutritiva NIN',
        file: protocol_1,
        date: 'Noviembre 6, 2024',
        image: img_1
    }
]


const AdminInformesCientificos = () => {

    const [openDialog, setOpenDialog] = useState(false)

    const handleOpen = () => {
        setOpenDialog(true)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [reports, setReports] = useState(data)

    //drag
    const handleDrag = (result) => {
        if (!result.destination) return;

        const arr = [...reports];

        //Changing the position of Array element
        let removedItem = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, removedItem);

        //Updating the list
        setReports(arr);
    };

    //valores para localstorage (unicamente pruebas)
    const loaded = localStorage.getItem("informes")
        ? JSON.parse(localStorage.getItem("informes"))
        : [];

    const [formValues, setFormValues] = useState(loaded);

    return (
        <>
            <Container maxWidth='xl' sx={{ mb: 5 }}>
                <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '28px' }} paragraph>Lista de informes científicos publicados</Typography>
                {/* <Stack spacing={4}> */}

                <DragDropContext onDragEnd={handleDrag}>
                    <Droppable droppableId="reports" direction='vertical'>
                        {(provided) => (
                            <Stack spacing={4} {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    reports.map(({ id, name, file, date, image }, index) => (
                                        <Draggable
                                            key={id}
                                            draggableId={id.toString()}
                                            index={index}
                                        >
                                            {(provided) => (

                                                <Box
                                                    className='inf_box'
                                                    sx={{ p: 2 }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Box className='inf_content'>
                                                        <Box className='inf_ini'>
                                                            <Box sx={{ my: 'auto' }}>
                                                                <img src={image} alt='informe_científico' className='img_inf' />
                                                            </Box>
                                                            <Box sx={{ my: 'auto' }}>
                                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Nombre:</Typography>
                                                                <Typography variant='body1'> {name} </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ my: 'auto' }}>
                                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Fecha de publicación:</Typography>
                                                            <Typography variant='body1'> {date} </Typography>
                                                        </Box>
                                                        <Box sx={{ my: 'auto' }}>
                                                            <IconButton onClick={handleOpenMenu}>
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                        </Box>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem onClick={handleClose}>
                                                                <ListItemIcon>
                                                                    <EditIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText>Editar</ListItemText>
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                <ListItemIcon>
                                                                    <DeleteIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText>Eliminar</ListItemText>
                                                            </MenuItem>
                                                            <MenuItem onClick={() => {
                                                                window.open(
                                                                    file,
                                                                    '_blank'
                                                                );
                                                            }}>
                                                                <ListItemIcon>
                                                                    <VisibilityIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText>Ver documento en otra pestaña</ListItemText>
                                                            </MenuItem>
                                                        </Menu>
                                                    </Box>
                                                </Box>

                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                </DragDropContext>


                {/* <DragDropContext onDragEnd={handleDrag}>
                    <Droppable droppableId="drag-drop-list" direction='vertical'>
                        {(provided, snapshot) => (
                            <div
                                className="drag-drop-list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {reports.map((res, index) => (
                                    <Draggable
                                        key={res.id}
                                        draggableId={res.text}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                className="item-card"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {res.name}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> */}



            </Container>

            <CustomFab sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                <AddIcon sx={{ color: '#fff' }} />
            </CustomFab>

            <InformesCientificosModal openDialog={openDialog} setOpenDialog={setOpenDialog} formValues={formValues} setFormValues={setFormValues} />
        </>
    )
}

export default AdminInformesCientificos