import React, { useEffect, useRef, useState } from 'react'

//MUI
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputBase, Paper, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';

//css
import './Webinars.css'

//swal
import { basicTimerMsg } from '../../../actions/swal_msg'

//Datepicker MUI
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';

const init = {
    name: '',
    date: new Date(),
    file: ''
}

const initErr = {
    name_err: ''
}

const AddWebinar = ({ dialog, setDialog, formValues, setFormValues }) => {

    //Media querys
    const matches = useMediaQuery('(max-width: 500px)')

    const handleCloseDialog = () => {
        setDialog(false)
    }

    //file ref
    const fileRef = useRef();

    const [webinar, setWebinar] = useState(init)
    const { name, date, file } = webinar

    const [inputErr, setInputErr] = useState(initErr);
    const { name_err } = inputErr

    const name_ref = useRef();

    //on change file
    const changeHandler = ({ target }) => {
        if (target.files.length !== 0) {
            setWebinar(e => ({
                ...e,
                file: URL.createObjectURL(target.files[0])
            }))
        }
    };

    //delete file
    const deleteFile = () => {
        setWebinar(e => ({
            ...e,
            file: ''
        }));
        fileRef.current.value = '';
    }

    const onChange = ({ target }) => {
        const name = target.name
        const value = target.value;
        setWebinar(e => ({
            ...e,
            [name]: value
        }))
        switch (name) {
            case 'name':
                setInputErr({
                    ...inputErr,
                    name_err: (value.length < 2 ||
                        value.length > 50) ? true : false
                });
                break;
            default:
                break;
        }
    }

    const formValidator = () => {
        const name_len = name.trim().length
        if (name_len > 1 && name_len <= 50) {
            if (date) {
                if (file !== '') {
                    return true
                }
                else {
                    basicTimerMsg('Agregue el documento')
                }
            }
            else {
                basicTimerMsg('Ingrese una fecha valida')
            }
        }
        else {
            basicTimerMsg('Por favor ingrese el titulo del webinar')
            name_ref.current.select();
            setInputErr({
                ...inputErr,
                name_err: true
            });
        }
    }

    //Submit form
    const submit = (e) => {
        e.preventDefault();
        if (formValidator()) {
            alert('Guardar datos en la BD')
            setFormValues((prevFormValues) => [...prevFormValues, webinar]);
            setTimeout(() => {
                setWebinar(init)
            }, 2000)
        } else {
            console.log('Algo no anda bien')
        }
    };

    useEffect(() => {
        localStorage.setItem("webinar", JSON.stringify(formValues));
    }, [formValues]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            open={dialog}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Añadir nuevo webinar</DialogTitle>
            <DialogContent>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name='name'
                                    value={name}
                                    onChange={onChange}
                                    error={name_err}
                                    helperText={name_err ? "Ingrese el titulo del webinar" : ""}
                                    inputRef={name_ref}
                                    variant="outlined"
                                    label='Titulo/nombre de webinar'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                <DatePicker
                                    label='Fecha de webinar'
                                    views={['year', 'month', 'day']}
                                    value={date}
                                    onChange={(newValue) => {
                                        setWebinar({ ...webinar, date: newValue });
                                    }}
                                    slotProps={{
                                        actionBar: { actions: ["today", "cancel", "accept"] },
                                        textField: { inputProps: { readOnly: true }, fullWidth: true },

                                    }}

                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            {
                                file !== ''
                                    ?
                                    null
                                    :
                                    <>
                                        <Box className='webinar_upload_file_container' onClick={() => { fileRef.current.click() }}>
                                            <Box className='webinar_upload_file_content'>
                                                <UploadIcon sx={{ fontSize: '4rem', mb: 2 }} />
                                                <Typography variant='body1' paragraph>Cargar video</Typography>
                                                <Typography variant='body1'>Sólo se aceptan archivos de tipo MP4, X-M4V, entre otros formatos de vídeo</Typography>
                                            </Box>
                                        </Box>
                                    </>
                            }
                            {
                                file !== ''
                                    ?
                                    <Box className='video_wrapper' sx={{ mt: 3 }}>
                                        <video src={file} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                                            controls="controls" controlsList="nodownload"
                                        />
                                    </Box>
                                    :
                                    null
                            }
                            <Box display='flex' justifyContent='flex-start' gap='10px' flexWrap='wrap' mt={2}>
                                <Box sx={{ my: 'auto' }}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        sx={{ textTransform: 'none' }}
                                    >
                                        Subir archivo
                                        <input
                                            accept="video/mp4,video/x-m4v,video/*"
                                            type="file"
                                            hidden
                                            ref={fileRef}
                                            onChange={changeHandler}
                                        />
                                    </Button>
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    
                                        <Paper
                                            component="form"
                                            sx={{ display: 'flex', alignItems: 'center', width: matches ? '260px' : '300px', height: '38px', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;' }}
                                        >
                                            <Tooltip title={file}>
                                            <InputBase
                                                sx={{ flex: 1, ml: 1 }}
                                                value={file !== '' ? `${file}` : 'Ningún archivo seleccionado'}
                                                disabled
                                            />
                                            </Tooltip>
                                            {
                                                file !== ''
                                                    ?
                                                    <>
                                                        <Divider sx={{ height: 28, mr: 0, ml: 1.5 }} orientation="vertical" />
                                                        <IconButton sx={{ p: '10px', color: '#e74c3c' }} onClick={deleteFile}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </Paper>
                                    
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cerrar</Button>
                <Button variant='contained' onClick={submit}>Añadir webinar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddWebinar