import React, { useState } from 'react'

import './AdminProtocols.css'

//MUI
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import img from '../../../Assets/Images/Simbiotico.jpg'
import img_1 from '../../../Assets/Images/Malteada-Nutritiva-vainilla-490g_1.jpg'

import protocol from '../../../Assets/Resources/protocolos/reporte_preliminar_simbiotico_nin.pdf'
import protocol_1 from '../../../Assets/Resources/protocolos/Malteada_Nutritiva_NIN_preliminar_junio-2024.pdf'

//components
import ProtocolModal from './ProtocolModal';

//react-beatufil-dnd
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fffff',
  backgroundColor: '#283A49',
  '&:hover': {
    backgroundColor: '#405668',
  },
}));

const data = [
  {
    id: 1,
    name: 'Protocolo Simbiotico NIN',
    file: protocol,
    date: 'Noviembre 4, 2024',
    image: img
  },
  {
    id: 2,
    name: 'Protocolo Malteada nutritiva NIN',
    file: protocol_1,
    date: 'Noviembre 6, 2024',
    image: img_1
  }, {
    id: 3,
    name: 'Protocolo Malteada nutritiva NIN',
    file: protocol_1,
    date: 'Noviembre 6, 2024',
    image: img_1
  }
  , {
    id: 4,
    name: 'Protocolo Malteada nutritiva NIN',
    file: protocol_1,
    date: 'Noviembre 6, 2024',
    image: img_1
  }
]

const AdminProtocols = () => {

  const matches = useMediaQuery('(max-width: 480px)')

  const [openDialog, setOpenDialog] = useState(false)

  const handleOpen = () => {
    setOpenDialog(true)
  }

  const [protocols, setProtocols] = useState(data)

  //drag
  const handleDrag = (result) => {
    if (!result.destination) return;

    const arr = [...protocols];

    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    //Updating the list
    setProtocols(arr);
  };

  //valores para localstorage (unicamente pruebas)
  const loaded = localStorage.getItem("protocol")
    ? JSON.parse(localStorage.getItem("protocol"))
    : [];

  const [formValues, setFormValues] = useState(loaded);

  return (
    <>
      <Container maxWidth='xl' sx={{ mb: 5 }}>
        <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '28px' }} paragraph>Lista de protocolos publicados</Typography>
        <Button variant='contained' sx={{ textTransform: 'none', mb: 5 }} endIcon={<AddIcon />} onClick={handleOpen}>Añadir nuevo protocolo</Button>

        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="protocols">
            {(provided) => (
              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef}>
                {
                  protocols.map((res, index) => (
                    <Draggable
                      key={res.id}
                      draggableId={res.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box className='protocol_box' sx={{ p: 2 }}>
                            <Box className='protocol_content'>
                              <Box className='protocol_ini'>
                                <Box sx={{ my: 'auto' }}>
                                  <img src={res.image} alt='protocol' className='img_protocol' />
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                  <Typography variant='body1'>{res.name}</Typography>
                                  <a href={res.file} target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>Visualizar documento</a>
                                </Box>
                              </Box>
                              <Box sx={{ my: 'auto' }}>
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Fecha de publicación:</Typography>
                                <Typography variant='body1'> {res.date} </Typography>
                              </Box>
                            </Box>
                            <Stack direction='row' spacing={2} sx={{ mt: 2, justifyContent: matches ? 'center' : 'flex-start' }}>
                              <CustomButton size='small' variant='contained' startIcon={<EditIcon />}>Editar</CustomButton>
                              <CustomButton size='small' variant='contained' startIcon={<DeleteIcon />}>Eliminar</CustomButton>
                            </Stack>
                          </Box>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Container>

      <ProtocolModal openDialog={openDialog} setOpenDialog={setOpenDialog} formValues={formValues} setFormValues={setFormValues} />
    </>
  )
}

export default AdminProtocols