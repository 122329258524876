import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// import { AdminContext } from '../../../context/AdminContext';

// import { loadWebsiteData } from '../../../requests/admin/admin';

//MUI
import {
  Box, Card, CardActionArea, CardContent, Container, Divider, Grid, Typography
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NoteIcon from '@mui/icons-material/Note';
import FeedIcon from '@mui/icons-material/Feed';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

const IndexAdmin = () => {
  // const { setData } = useContext(AdminContext);

  useEffect(() => {
    // loadWebsiteData(setData);
  }, []);

  return (
    <Container maxWidth='xl' sx={{ my: 8 }}>
      <Grid container spacing={4}>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/admins'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <AdminPanelSettingsIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Administradores del sitio</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Crear, editar o eliminar administradores del sitio web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/blog'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <BookIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Blog</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Crear, editar o eliminar entradas de blog para el sitio web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/banners'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <ViewCarouselIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Banners del sitio</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Editar o eliminar imagenes principales del sito web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/protocolos'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <NoteIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Protocolos</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Agregar, editar o eliminar protocolos del sitio web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/informes-cientificos'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <FeedIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Informes científicos</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Agregar, editar o eliminar informes científicos del sitio web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{display: 'flex'}}>
          <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardActionArea LinkComponent={Link} to='/nin-admin/webinars'>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Box sx={{ my: 'auto' }}>
                    <PersonalVideoIcon fontSize='medium' />
                  </Box>

                  <Box sx={{ my: 'auto' }}>
                    <Typography variant='h6'>Webinars</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                  Agregar o eliminar webinars del sitio web
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default IndexAdmin