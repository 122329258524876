import React, { useContext, useState } from 'react';

import './HeaderAdmin.css';

//Router dom
import { Link } from 'react-router-dom';

//Context
import { RouterContext } from '../../../context/RouterContext';

//MUI
import {
  AppBar, Avatar, Box, Drawer, IconButton, Menu, Toolbar,
  Typography, MenuItem, List, ListItemButton, ListItemIcon,
  ListItemText, Divider
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NoteIcon from '@mui/icons-material/Note';
import FeedIcon from '@mui/icons-material/Feed';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

import logo from '../../../Assets/Images/logo_NIN.png';

const HeaderAdmin = () => {
  const { item: { username }, userLogout } = useContext(RouterContext);
  
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //Menu avatar
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const adminLinks = [
    {
      title: 'Inicio',
      url: '',
      icon: HomeIcon
    },
    {
      title: 'Entradas',
      url: 'blog',
      icon: ViewComfyIcon
    },
    {
      title: 'Nueva entrada',
      url: 'nuevo-blog',
      icon: LibraryAddIcon
    },
    {
      title: 'Admins',
      url: 'admins',
      icon: AdminPanelSettingsIcon
    },
    {
      title: 'Banners',
      url: 'banners',
      icon: ViewCarouselIcon
    },
    {
      title: 'Protocolos',
      url: 'protocolos',
      icon: NoteIcon
    },
    {
      title: 'Informes científicos',
      url: 'informes-cientificos',
      icon: FeedIcon
    },
    {
      title: 'Webinars',
      url: 'webinars',
      icon: PersonalVideoIcon
    },
  ];

  return (
    <Box sx={{ display: 'flex', mb: 8 }}>
      <AppBar position="sticky" style={{ background: '#ffff', zIndex: '2' }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: 'black' }}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ mt: .5 }}>
            <Link to="/">
              <img src={logo} width="90" alt="NIN-Institute" />
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav"
        aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: { width: "280px" },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className='img-admin-sidebar-header bg-wrap'>
            <div className="user-logo">
              <Avatar sx={{ backgroundColor: '#2699FB', fontWeight: 'bold' }} className='img-user-logo'>{username.charAt(0)}</Avatar>
              <div>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                    <Typography variant='body1' sx={{ fontWeight: '600', color: '#ffff', my: 'auto' }}>{username}</Typography>
                    <ArrowDropDownIcon sx={{ color: '#fff', my: 'auto' }} />
                  </Box>
                </IconButton>
                <Menu
                  sx={{ mt: '25px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={ userLogout }>
                    <LogoutIcon sx={{ mr: 2 }} />
                    Cerrar sesión
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <List component="div" disablePadding>
            {
              adminLinks.map(e => (
                <React.Fragment key={e.url}>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => { setMobileOpen(false) }}
                    component={Link}
                    to={"/nin-admin/" + e.url}>
                    <ListItemIcon>
                      <e.icon />
                    </ListItemIcon>
                    <ListItemText primary={e.title} />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))
            }
          </List>
        </Drawer>
      </Box>
    </Box>
  )
}

export default HeaderAdmin